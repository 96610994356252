/* -----------------Globals--------------- */
import * as React from 'react';

/* -----------------Next js--------------- */
import Head from 'next/head';
import { AppProps } from 'next/app';
import {
  useRouter,
} from 'next/router';

/* -----------------Third parties--------------- */
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import dynamic from 'next/dynamic';

/* -----------------UI--------------- */
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';

/* -----------------Analytics--------------- */
import {
  initMixpanel,
} from '../src/analytics/tracking';

/* -----------------Types--------------- */
import {
  Tenant,
} from '../src/types/tenant';

/* -----------------Redux--------------- */
import {
  wrapper,
} from '../src/redux/store';

/* -----------------Providers--------------- */
import {
  AuthProvider,
} from '../src/context/AuthContext';
import {
  TenantProvider,
} from '../src/context/TenantContext';
import {
  RelayEnvironmentProvider,
} from 'react-relay';
import {
  PageProvider,
} from '../src/context/PageContext';

/* -----------------Config--------------- */
import theme from '../src/theme';
import createEmotionCache from '../src/createEmotionCache';
import setUpAmplifyConfig from '../src/configs/amplify';
import environment from '@relay/env';
import Footer from '@components/chrome/footer/Footer';

import './input.css';

Auth.configure(setUpAmplifyConfig());
API.configure(setUpAmplifyConfig());

const ZendeskWidget = dynamic(() => import('../src/components/widgets/ZendeskChat'), { ssr: false });

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any
  }
}
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface CustomAppProps extends AppProps {
  title : string;
  emotionCache?: EmotionCache;
}

const CustomApp = (props: CustomAppProps) => {
  const {
    Component, emotionCache = clientSideEmotionCache, pageProps,
    pageProps: {
      title,
    },
  } = props;

  React.useEffect(() => {
    initMixpanel();
  }, []);

  const {
    pathname,
    isReady,
  } = useRouter();
  const initZendesk = pathname.includes('dashboard') || pathname === '/';

  React.useEffect(() => {
    if (window && window.zE && isReady) {
      if (initZendesk) {
        window.zE(() => {
          window.zE.show();
        });
      } else {
        window.zE(() => {
          window.zE.hide();
        });
      }
    }
  }, [initZendesk, pageProps, isReady]);
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{title || 'NAPLEX Practice Test Central'}</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <RelayEnvironmentProvider environment={environment}>
          <TenantProvider tenant={Tenant.mh}>
            <PageProvider>
              <AuthProvider>
                <Component {...pageProps} />
                <Footer />
                <ZendeskWidget />
              </AuthProvider>
            </PageProvider>
          </TenantProvider>
        </RelayEnvironmentProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default wrapper.withRedux(CustomApp);
